import "./App.css";
import { Route, Routes } from "react-router-dom";

import { Context } from "./context/Context";
import Footer from "./component/footer/Footer";
import Header from "./component/header/Header";
import Home from "./component/home/Home";
import MeetOurVendor from "./pages/MeetOurVendor";
import MarketMap from "./pages/MarketMap";
import ApplyHere from "./pages/ApplyHere";
import useApiAxios from "./api/useApiAxios";
import { useContext, useEffect, useState } from "react";
import SnackbarComponent from "./component/snackbarComponent/SnackbarComponent";
import Loader from "./component/loader/Loader";

function App() {
  const [loading, setLoading] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchMarketData();
  }, []);

  const { setMarketData, marketData } = useContext(Context);

  const { getMarketContentApi } = useApiAxios();

  const [marketApplyData, setMarketApplyData] = useState(null);
  const [marketMapData, setMarketMapData] = useState(null);

  const [marketAllData, setMarketAllData] = useState(null);


  const fetchMarketData = async () => {
    try {
      setLoading(true)
      const data = await getMarketContentApi();
      setMarketData(data.data.data.marketContent);
      setMarketMapData(data.data.data)
      setMarketApplyData(data.data.data)
      setMarketAllData(data.data.data)
      setTimeout(() => {
        setLoading(false);
      }, 2000); 

    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  const headerColor = marketData?.headerColor || "#ffffff";
  const footerColor = marketData?.footerColor || "#000000"; 
  return (
    <div>
      <Header marketData={marketData} />
      <SnackbarComponent />
      <Loader open={loading} /> {/* Render Loader component with loading state */}
    
      <Routes>
        <Route path="/" element={<Home fetchMarketData={fetchMarketData} />} />
        <Route path="/meetourvendor" element={<MeetOurVendor />} />
      
        <Route path="/marketmap" element={<MarketMap marketMapData={marketMapData} />} />
        <Route path="/apply-here" element={<ApplyHere marketApplyData={marketApplyData} />} />
      

      </Routes>

      <Footer marketData={marketData} marketAllData={marketAllData}/>
      <div id="back-top"  style={{ color: footerColor , background:headerColor}}>
        <a title="Go to Top" onClick={scrollToTop}>
          <i className="fas fa-level-up-alt"
             // Apply the headerColor to the icon
                      />
        </a>
      </div>
    </div>
  );
}

export default App;
